import React, { useState, useEffect } from "react";
import logo from "../assets/img/time-e.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../assets/css/style.css";
import { baseurl } from "../App";
import axios from "axios";
const Footer = ({ currentLang }) => {
  const { t } = useTranslation();
  const [companyprofile_data, setcompanyprofile] = useState([]);

  useEffect(() => {
    Companyprofilecontrol();
  }, []);

  const Companyprofilecontrol = async () => {
    try {
      let response = await axios.get("admin/getcompanyprofile");
      setcompanyprofile(response.data.companyprofile); 
       console.log("companyprofile", response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="footer">
      <footer style={{ position: "relative", padding: "50px 0 100px 0" }}>
        <hr className="colored" />
        <div className="container" style={{ paddingTop: "5%" }}>
          <div className="row gx-0">
            <div className="col-md-12 d-flex justify-content-start">
              <img
                alt=""
                src={logo}
                style={{ width: 100, height: "auto", marginBottom: 20 }}
              />
            </div>

            <div className="col-md-6 footer_child">
              <div
                className={
                  currentLang === "en" ? " footer_child" : "footer_child_ar"
                }
              >
                <p>
                  {t(
                    "King Fahad Rd. | Hamad Tower, 5th floor Riyadh, Saudi Arabia"
                  )}
                </p>
                <div className="row  gx-0 rowicons">
                  <div className="divoutericon">
                    <a
                      href="https://www.instagram.com/timeentsa/ "
                      target="_blank"
                      className="fa fa-instagram faicons"
                    />
                  </div>
                  <div className="divoutericon">
                    <a
                      href="https://www.linkedin.com/company/time-entertainment/mycompany/"
                      target="_blank"
                      className="fa fa-linkedin faicons"
                    >
                      {" "}
                    </a>
                  </div>
                  <div className="divoutericon">
                    <a
                      href="https://twitter.com/timeentsa?lang=ar"
                      target="_blank"
                      className="fa fa-twitter faicons"
                    >
                      {" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <Link to="/careers">
                <p className="pfooter">
                  {t("Careers")} <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              <Link to="/contacts">
                <p className="pfooter">
                  {t("Contact Us")}
                  <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              <Link to="/forsuppliers">
                <p className="pfooter">
                  {t("Supplier Portal")}{" "}
                  <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              <Link to="/ourloaction">
                <p className="pfooter">
                  {t("Office Location")}
                  <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/aboutus">
                <p className="pfooter">
                  {t("About us")}
                  <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              <Link to="/whatwedo">
                <p className="pfooter">
                  {t("What we do")} <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              <Link to="/ourwork">
                <p className="pfooter">
                  {t("Our work")} <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
             

              {companyprofile_data && companyprofile_data.map((item) => {
              return (
                <Link to={ currentLang === "en" ?
                `https://timeent.rvamp.com/storage/${item.companyprofile_en}` : `https://timeent.rvamp.com/storage/${item.companyprofile_ar}`}>
                <p className="pfooter">
                  {t("Company Profile")} <span className="pfooterspan">&gt;</span>
                </p>
              </Link>
              );
            })}

            </div>
          </div>
        </div>
        <div
          className="container text-center"
          style={{
            maxWidth: "100%",
            background: "black",
            position: "absolute",
            padding: 10,
            bottom: 0,
          }}
        >
          <p>{t("Copyright")}</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
