import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './assets/fonts/font-awesome.min.css'
import './assets/css/slick/slick.css'
import './assets/css/slick/slick-theme.css'
// import './assets/css/main.css'
import './assets/css/phonecss.css'
import './assets/js/timeent.js'
import { BrowserRouter } from "react-router-dom"
import 'animate.css/animate.min.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

